import { LOCAL_STORAGE_KEYS } from '@/constants/keys';

const saveCurrentLanguage = (lang: Languages) => {
    window.localStorage.setItem(LOCAL_STORAGE_KEYS.Language, lang);
};

const getCurrentLanguage = () => {
    const language: Languages | null = window.localStorage.getItem(
        LOCAL_STORAGE_KEYS.Language
    ) as Languages;

    return language ?? 'bg';
};

export { saveCurrentLanguage, getCurrentLanguage };
