export default {
    apiAddress: `${process.env.REACT_APP_BACKEND_URI!}/api`,
    authenticationUrl: `${process.env.REACT_APP_BACKEND_URI!}/api/Account/Login`,
    establishmentTypesUrl: `${process.env
        .REACT_APP_BACKEND_URI!}/api/MenuBuilder/GetEstablishmentTypes`,
    buildMenuUrl: `${process.env.REACT_APP_BACKEND_URI!}/api/MenuBuilder/BuildMenu`,
    noRgbSummaryUrl: `${process.env.REACT_APP_BACKEND_URI!}/api/RgbSummary/GetNoRgbSummary`,
    rgbSummaryUrl: `${process.env.REACT_APP_BACKEND_URI!}/api/RgbSummary/GetRgbSummary`,
    googleAnalyticsID: 'G-VLW2B78B8V',

    refreshTokenConfigs: {
        refreshTokenUrl: `${process.env.REACT_APP_BACKEND_URI!}/api/Login/RefreshToken`,
        closeToTokenExpirationTime: 300000 // Near to expire is if the remaining time is under 5 minutes (300000 miliseconds)
    }
};
