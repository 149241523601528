import { Button, Form, FormProps } from 'antd';
import styled from 'styled-components';

export const AuthFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 ${({ theme }) => theme.space[40]};
`;

export const AuthHeaderContainer = styled.div`
    text-align: center;
    min-height: ${({ theme }) => theme.space[100]};
    padding: 0 ${({ theme }) => theme.space[20]};
`;

export const AuthHeader = styled.h1`
    font-size: ${({ theme }) => theme.fontSize[24]};
    color: ${({ theme }) => theme.color.default.black};
    margin-bottom: ${({ theme }) => theme.space[16]};
`;

export const AuthForm: React.FunctionComponent<FormProps> = styled(Form)`
    width: 100%;
`;

export const AuthButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    bottom: 10vh;
    left: 50%;
    transform: translate(-50%, 0);
`;

export const AuthButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 200px;
    padding: 0px 12px;
    border-radius: 4px;
    font-size: ${({ theme }) => theme.fontSize[16]};
    font-weight: 700;
    color: ${({ theme }) => theme.color.default.white};
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px;
    background-color: ${({ theme }) => theme.color.other.beerLight};
    cursor: pointer;

    &:hover {
        color: ${({ theme }) => theme.color.other.beerDark} !important;
        border-color: ${({ theme }) => theme.color.default.white} !important;
    }
`;
