import { useState } from 'react';

import { Button } from 'antd';

import { FormatFilterDropdownItem } from '@/types/format-filters';
import { MenuItemClickEvent } from '@/types/menu-item-event';

import { StyledDropdown } from '../../styles';

type Props = {
    items: FormatFilterDropdownItem[] | undefined;
    handler: (e: MenuItemClickEvent) => void;
    label: string;
};

const FormatFilterDropdown: React.FC<Props> = ({ items, handler, label }) => {
    const [selectedItem, setSelectedItem] = useState<string>(label);

    const handleMenuItemClick = (e: MenuItemClickEvent) => {
        const selectedKey = +e.key;
        const selectedItem = items?.find((item) => item.key === selectedKey)?.label;

        if (selectedItem) {
            setSelectedItem(selectedItem);
        }

        handler(e);
    };

    return (
        <StyledDropdown
            menu={{
                items,
                onClick: handleMenuItemClick
            }}>
            <Button>{selectedItem}</Button>
        </StyledDropdown>
    );
};

export default FormatFilterDropdown;
