import { useTranslation } from 'react-i18next';

import { ROUTES } from '@/constants';

import { NavLinkContainer, StyledNavLink } from './styles';

const NavigationLinks = () => {
    const { t } = useTranslation('translation');

    return (
        <>
            <NavLinkContainer>
                <StyledNavLink to={ROUTES.Default}>{t('BY_FORMAT')}</StyledNavLink>
                {/* <StyledNavLink to={ROUTES.NoRGB}>{t('NO_RGB')}</StyledNavLink> */}
                {/* <StyledNavLink to={ROUTES.RGB}>t('RGB')</StyledNavLink> */}
            </NavLinkContainer>
        </>
    );
};

export default NavigationLinks;
