import { useEffect, useState } from 'react';

import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

import { establishmentMap } from '@/constants/establishment-map';
import { PocTypeEnum } from '@/enums/poc-type.enum';
import useGetMenuData from '@/hooks/useGetMenuData';
import useLanguage from '@/hooks/useLanguage';
import { FormatFilterDropdownItem } from '@/types/format-filters';
import { MenuItemClickEvent } from '@/types/menu-item-event';

import FormatFilterDropdown from './partials/FormatFilterDropdown';
import { DropdownContainer } from './styles';

type Props = {
    menuType: string;
    setByFormatData: (value: any) => void;
};

const FormatFilters: React.FC<Props> = ({ menuType, setByFormatData }) => {
    const [establishmentItems, setEstablishmentItems] = useState<FormatFilterDropdownItem[]>();
    const [establishmentType, setEstablishmentType] = useState<number>();
    const [pocType, setPocType] = useState<number>();
    const { data, getBuildMenuData, getEstablishmentTypes } = useGetMenuData({ menuType });
    const { t } = useTranslation('translation');
    const { currentLanguage } = useLanguage();

    const pocTypeItems = [
        {
            label: t('NO_RGB'),
            key: PocTypeEnum.NoRGB
        },
        {
            label: t('RGB'),
            key: PocTypeEnum.RGB
        }
    ];

    const translateEstablishmentItems = (
        establishmentItems: FormatFilterDropdownItem[] | undefined
    ) => {
        const translatedItems = establishmentItems?.map((item) => {
            item.label = t(establishmentMap[item.label]);

            return item;
        });

        setEstablishmentItems(translatedItems);
    };

    const formatResponseKeys = (response: AxiosResponse) => {
        if (!response) {
            return;
        }

        const arr = Object.entries(response.data).map(([key, value]) => {
            const formattedKey = key.replace(/([A-Z])/g, ' $1').trim();

            return {
                label: formattedKey,
                key: value as number
            };
        });

        if (currentLanguage === 'bg') {
            translateEstablishmentItems(arr);

            return;
        }

        setEstablishmentItems(arr);
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await getEstablishmentTypes();

            if (response) {
                formatResponseKeys(response);
            }
        };

        fetchData();
    }, [currentLanguage]); // eslint-disable-line

    useEffect(() => {
        if (establishmentType && pocType) {
            getBuildMenuData(establishmentType, pocType);
        }
    }, [establishmentType, pocType]); // eslint-disable-line

    useEffect(() => {
        setByFormatData(data);
    }, [data, setByFormatData]);

    const formatHandler = (e: MenuItemClickEvent) => {
        setEstablishmentType(+e.key);
    };

    const typeHandler = (e: MenuItemClickEvent) => {
        setPocType(+e.key);
    };

    return (
        <DropdownContainer>
            <FormatFilterDropdown
                key={(currentLanguage as string) + 'establishment'}
                items={establishmentItems}
                handler={formatHandler}
                label={t('ESTABLISHMENT')}
            />
            <FormatFilterDropdown
                key={(currentLanguage as string) + 'type'}
                items={pocTypeItems}
                handler={typeHandler}
                label={t('TYPE')}
            />
        </DropdownContainer>
    );
};

export default FormatFilters;
