import { StarFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { logoMap } from '@/constants/logo-map';
import { BeerSubcategoryType, ByFormatDataType } from '@/types/response-data';

import {
    BeerContainer,
    BeerLogo,
    BeerLogoContainer,
    BeerMenuCategory,
    BeerMenuOneCol,
    BeerMenuSubcategoryLabel,
    BeerMenuWrapper,
    BeerName,
    BeerNameContainer,
    BeerSubcategoryContainer
} from '../../styles';

type Props = {
    data: ByFormatDataType | undefined;
};

const OneColLayout: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation('translation');

    if (!data) {
        return <> </>;
    }

    return (
        <BeerMenuOneCol>
            <BeerMenuWrapper>
                {data?.beerSubcategories?.map(
                    (beerSubcategory: BeerSubcategoryType, idx: number) => (
                        <BeerSubcategoryContainer key={idx}>
                            <BeerMenuCategory>
                                {beerSubcategory?.beers.length > 0 && (
                                    <>
                                        <BeerMenuSubcategoryLabel>
                                            {beerSubcategory.beerSubcategoryEnum === 1
                                                ? t('DRAUGHT')
                                                : beerSubcategory.beerSubcategoryEnum === 2
                                                ? t('GLASS')
                                                : t('NON_ALCOHOLIC')}
                                        </BeerMenuSubcategoryLabel>
                                        <hr />
                                    </>
                                )}
                            </BeerMenuCategory>
                            {beerSubcategory?.beers?.map((beer, idx) => (
                                <BeerContainer key={`${beer.beerName}${idx}`}>
                                    <BeerLogoContainer>
                                        <BeerLogo src={logoMap[beer.id]} alt={beer.beerName} />
                                    </BeerLogoContainer>
                                    <BeerNameContainer>
                                        <BeerName>
                                            {beer.beerName}
                                            {beer?.isRequired && (
                                                <StarFilled className="star-icon" />
                                            )}
                                        </BeerName>
                                    </BeerNameContainer>
                                </BeerContainer>
                            ))}
                        </BeerSubcategoryContainer>
                    )
                )}
            </BeerMenuWrapper>
        </BeerMenuOneCol>
    );
};

export default OneColLayout;
