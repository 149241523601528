export const establishmentMap: Record<string, string> = {
    'Bar And Dinner': 'BAR_AND_DINNER',
    'Bar Core': 'BAR_CORE',
    'Bar Premium': 'BAR_PREMIUM',
    'Fast Food': 'FAST_FOOD',
    Casino: 'CASINO',
    'Cafe Core': 'CAFE_CORE',
    'Cafe Premium': 'CAFE_PREMIUM',
    'Aperitif Bar': 'APERITIF_BAR',
    Horemag: 'HOREMAG',
    'Guest House': 'GUEST_HOUSE',
    'Lobby Bar': 'LOBBY_BAR',
    Tavern: 'TAVERN',
    'Night Club': 'NIGHT_CLUB',
    Pizzeria: 'PIZZERIA',
    Pub: 'PUB',
    'Restaurant Core': 'RESTAURANT_CORE',
    'Restaurant Premium': 'RESTAURANT_PREMIUM',
    'Sport Cafe': 'SPORT_CAFE',
    'Sport Cafe Premium': 'SPORT_CAFE_PREMIUM'
};
