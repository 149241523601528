import { Layout } from 'antd';
import { Footer, Header } from 'antd/es/layout/layout';
import styled from 'styled-components';

export const StyledLayout = styled(Layout)`
    max-width: 450px;
    height: 100vh;
    color: white;
    overflow: hidden;
    margin: 0 auto;
`;

export const StyledHeader = styled(Header)`
    height: auto;
    padding: 0;
    margin-bottom: ${({ theme }) => theme.space[20]};
    background-color: ${({ theme }) => theme.color.grey[60]};
`;

export const StyledFooter = styled(Footer)`
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    background-color: ${({ theme }) => theme.color.default.white};
    z-index: 10;
`;
