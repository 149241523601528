const ROUTES = {
    Default: '/',
    None: '*',
    Authentication: {
        Path: 'authentication',
        Login: 'login'
    },
    RGB: 'rgb',
    NoRGB: 'no-rgb',
    FullPaths: {
        // Authentication
        Login: '/authentication/login'
    }
};

export default ROUTES;
