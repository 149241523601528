import styled from 'styled-components';

import backgroundImage from '@/assets/images/background.webp';

type ContainerProps = {
    $height: number;
};

export const BeerMenuLayout = styled.div`
    height: 100%;
    overflow: scroll;
    text-align: center;
    padding: 0 ${({ theme }) => theme.space[16]};
    background-color: ${({ theme }) => theme.color.grey[60]};
`;

export const BeerMenuContainer = styled.div<ContainerProps>`
    background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6)), url(${backgroundImage});
    height: ${({ $height }) => `${$height}%`};
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
`;

export const BeerMenuHeadingContainer = styled.div`
    border: 1px solid;
    border-top: 0;
    margin: 0 auto;
    max-width: 200px;
    padding-bottom: ${({ theme }) => theme.space[12]};
    margin-bottom: ${({ theme }) => theme.space[40]};
    text-transform: uppercase;
    cursor: pointer;
    user-select: none;
`;

export const BeerMenuOneCol = styled.div`
    overflow: scroll;
    height: 80%;
`;

export const BeerMenuH1 = styled.h1`
    display: inline;
    font-weight: 400;
    font-size: ${({ theme }) => theme.fontSize[48]};
    letter-spacing: ${({ theme }) => theme.letterSpacing[2]};
`;

export const BeerMenuSubcategoryLabel = styled.h3`
    text-transform: uppercase;
    margin-bottom: 0;
    font-size: ${({ theme }) => theme.fontSize[20]};
`;

export const BeerMenuWrapper = styled.div``;

export const BeerMenuCategory = styled.div`
    max-width: 225px;
    margin-right: auto;
    margin-bottom: ${({ theme }) => theme.space[36]};
    text-align: right;
`;

export const BeerContainer = styled.div`
    display: flex;
    align-items: center;
    padding-left: ${({ theme }) => theme.space[40]};
    padding-right: ${({ theme }) => theme.space[24]};
    margin-bottom: ${({ theme }) => theme.space[12]};
`;

export const BeerSubcategoryContainer = styled.div`
    margin-bottom: ${({ theme }) => theme.space[56]};
`;

export const BeerLogoContainer = styled.div`
    width: 17%;
    height: 100%;
    margin-right: ${({ theme }) => theme.space[28]};
    min-width: ${({ theme }) => theme.space[60]};
`;

export const BeerLogo = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 2px;
    user-select: none;
`;

export const BeerNameContainer = styled.div`
    letter-spacing: ${({ theme }) => theme.letterSpacing['0.5']};
    font-weight: 500;
    text-align: left;
`;

export const BeerName = styled.span`
    margin-right: ${({ theme }) => theme.space[8]};
    font-size: ${({ theme }) => theme.fontSize[18]};
`;
