import { Content, Header } from 'antd/es/layout/layout';
import styled from 'styled-components';

export const StyledAuthHeader = styled(Header)`
    display: flex;
    justify-content: center;
    height: 35%;
    background-color: ${({ theme }) => theme.color.default.bg};
`;

export const AuthLogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 56px;
`;

export const AuthLogo = styled.img`
    display: flex;
    align-self: center;
    width: 150px;
`;

export const AuthLogoLabel = styled.span`
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize[24]};
    letter-spacing: -1px;
    position: relative;
    right: 12px;
    bottom: 12px;
    color: ${({ theme }) => theme.color.grey[30]};
    user-select: none;
`;

export const StyledAuthContent = styled(Content)`
    display: flex;
    justify-content: center;
    height: 65%;
    background-color: ${({ theme }) => theme.color.default.bg};
`;
