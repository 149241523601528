import styled from 'styled-components';

export const LogoContainer = styled.div`
    display: flex;
`;

export const LogoDescription = styled.span`
    padding: ${({ theme }) => theme.space[4]};
    margin-right: ${({ theme }) => theme.space[4]};
    color: ${({ theme }) => theme.color.grey[40]};
    font-size: ${({ theme }) => theme.fontSize[14]};
`;

export const LogoImage = styled.img``;
