import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const NavLinkContainer = styled.div`
    display: flex;
    justify-content: space-between;
    // padding: 0 ${({ theme }) => theme.space[16]};
    padding: 0 125px;
    margin-bottom: ${({ theme }) => theme.space[24]};

    a:nth-child(2) {
        border-left: none;
        border-right: none;
    }
`;

export const StyledNavLink = styled(NavLink)`
    position: relative;
    top: -1px;
    width: 100%;
    font-size: ${({ theme }) => theme.fontSize[14]};
    font-weight: 700;
    border: 0.5px solid;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    color: ${({ theme }) => theme.color.grey[30]};

    &:hover {
        color: ${({ theme }) => theme.color.default.black};
        border-color: ${({ theme }) => theme.color.grey[40]};
    }

    &.active {
        color: ${({ theme }) => theme.color.default.black};
        background-color: ${({ theme }) => theme.color.default.white};
        border-color: ${({ theme }) => theme.color.grey[40]};
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    }
`;
