import React from 'react';

import { Outlet } from 'react-router-dom';

import logoPath from '@/assets/images/logo.webp';
import FooterContent from '@/components/FooterContent';
import { StyledFooter, StyledLayout } from '@/styles';

import {
    AuthLogo,
    AuthLogoContainer,
    AuthLogoLabel,
    StyledAuthContent,
    StyledAuthHeader
} from './styles';

const Authentication: React.FC = () => {
    return (
        <StyledLayout>
            <StyledAuthHeader>
                <AuthLogoContainer>
                    <AuthLogo src={logoPath} alt="BeerLogo" />
                    <AuthLogoLabel>Menu Builder</AuthLogoLabel>
                </AuthLogoContainer>
            </StyledAuthHeader>
            <StyledAuthContent>
                <Outlet />
            </StyledAuthContent>
            <StyledFooter>
                <FooterContent />
            </StyledFooter>
        </StyledLayout>
    );
};

Authentication.defaultProps = {
    hasHeader: true
};

export default Authentication;
