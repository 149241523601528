import styled from 'styled-components';

import { BeerName, BeerMenuOneCol, BeerLogoContainer, BeerContainer } from '../../styles';

export const BeerMenuTwoCol = styled(BeerMenuOneCol)`
    display: flex;
    padding: 0 ${({ theme }) => theme.space[16]};
    gap: ${({ theme }) => theme.space[20]};
    height: 83%;
`;

export const TwoColName = styled(BeerName)`
    margin-right: 0;
    font-size: ${({ theme }) => theme.fontSize[14]};
`;

export const TwoColLogoContainer = styled(BeerLogoContainer)`
    margin-right: ${({ theme }) => theme.space[12]};
    min-width: ${({ theme }) => theme.space[40]};
`;

export const TwoColContainer = styled(BeerContainer)`
    padding: 0;
`;

export const PriorityCategory = styled.div`
    padding: 0 ${({ theme }) => theme.space[16]};
    margin-bottom: ${({ theme }) => theme.space[40]};
    font-weight: 700;
    font-size: ${({ theme }) => theme.fontSize[20]};
    text-transform: uppercase;
`;

export const TwoColSubcategoryLabel = styled.div`
    font-size: ${({ theme }) => theme.fontSize[18]};
    margin-bottom: ${({ theme }) => theme.space[16]};
    text-transform: uppercase;
`;
