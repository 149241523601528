export const fontSize = {
    8: '8px',
    9: '9px',
    10: '10px',
    11: '11px',
    12: '12px',
    14: '14px',
    16: '16px',
    18: '18px',
    20: '20px',
    22: '22px',
    24: '24px',
    25: '25px',
    30: '30px',
    48: '48px'
};
