import { Dropdown } from 'antd';
import styled from 'styled-components';

export const DropdownContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.space[8]};
    padding: 0 ${({ theme }) => theme.space[16]};
    margin-bottom: ${({ theme }) => theme.space[24]};
`;

export const StyledDropdown = styled(Dropdown)`
    display: flex;
    align-items: center;
    width: 100%;
    padding: ${({ theme }) => theme.space[16]};
    color: ${({ theme }) => theme.color.grey[80]};
    font-weight: 500;
    font-size: ${({ theme }) => theme.fontSize[14]};
    text-align: left;
    text-transform: capitalize;
`;
