export const color = {
    default: {
        white: '#fff',
        black: '#181818',
        bg: '#f8f8f8'
    },
    grey: {
        10: '#ebebeb',
        20: '#c8c8c8',
        30: '#9f9f9f',
        40: '#626262',
        50: '#555555',
        60: '#f5f5f5',
        70: '#8a8a8a',
        80: '#5c5c5c',
        90: '#d9d9d9'
    },
    red: {
        10: '#ffe7e3',
        20: '#ffccc5',
        30: '#ffa296',
        40: '#fb725f'
    },
    green: {
        10: '#c8f8e6',
        20: '#90e5c7',
        30: '#55dcab',
        40: '#15d490',
        50: '#3ac922',
        60: '#5ba296'
    },
    blue: {
        5: '#e0f2ff',
        10: '#f2f5ff',
        15: '#f0f9ff',
        20: '#e3e8ff',
        30: '#bfcbff',
        40: '#8ca1ff',
        50: '#4f6ffd',
        60: '#374cac'
    },
    documentStatus: {
        green: '#4de73f',
        yellow: '#ffeb3a',
        blue: '#53b7ff',
        red: '#ff5e3a'
    },
    other: {
        beerLight: '#ffcc4c',
        beerDark: '#f3900d'
    }
};
