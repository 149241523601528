import ReactGA from 'react-ga4';

import envconfig from '@/config/envconfig';

export const initGA = () => {
    ReactGA.initialize(envconfig.googleAnalyticsID);
};

export const logPageView = () => {
    window.gtag('config', envconfig.googleAnalyticsID, {
        page_path: window.location.pathname + window.location.search
    });
};
