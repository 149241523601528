import { useState, useEffect } from 'react';

import { message } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import envconfig from '@/config/envconfig';
import { MenuTypesEnum } from '@/enums/menu-type.enum';
import { RGBDataType } from '@/types/response-data';

type Props = {
    menuType: string;
};

const useGetMenuData = ({ menuType }: Props) => {
    const [data, setData] = useState<RGBDataType | undefined>();
    const { t } = useTranslation('translation');

    const getRgbSummaryData = async (url: string) => {
        try {
            const res = await axios.get(url);
            setData(res.data);
        } catch (error) {
            message.error(t('API_ERROR'));
        }
    };

    const getBuildMenuData = async (establishmentType: number, pocType: number) => {
        if (!establishmentType || !pocType) {
            return;
        }

        try {
            const res = await axios.get(
                `${envconfig.buildMenuUrl}?establishmentType=${establishmentType}&pocType=${pocType}`
            );

            setData(res.data);
        } catch (error) {
            message.error(t('API_ERROR'));
        }
    };

    const getEstablishmentTypes = async () => {
        try {
            const response = await axios.get(`${envconfig.establishmentTypesUrl}`);

            return response;
        } catch (error) {
            message.error(t('API_ERROR'));
        }
    };

    useEffect(() => {
        if (menuType === MenuTypesEnum.NoRGB) {
            getRgbSummaryData(envconfig.noRgbSummaryUrl);
        }

        if (menuType === MenuTypesEnum.RGB) {
            getRgbSummaryData(envconfig.rgbSummaryUrl);
        }
    }, [menuType]); // eslint-disable-line

    return {
        data,
        getRgbSummaryData,
        getEstablishmentTypes,
        getBuildMenuData
    };
};

export default useGetMenuData;
