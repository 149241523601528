import React from 'react';

import logo from '@/assets/svg/plana-logo.svg';

import { LogoContainer, LogoDescription, LogoImage } from './styles';

const FooterContent: React.FC = () => {
    return (
        <LogoContainer>
            <LogoDescription>Developed by</LogoDescription>
            <LogoImage alt="" src={logo} />
        </LogoContainer>
    );
};

export default FooterContent;
