import React from 'react';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import App from '@/App';
import BeerMenu from '@/components/BeerMenu';
import { ROUTES } from '@/constants';
import { MenuTypesEnum } from '@/enums/menu-type.enum';

import { Authentication, Login } from './authentication';

const Routing: React.FC = () => (
    <BrowserRouter>
        <Routes>
            <Route path={ROUTES.Authentication.Path} element={<Authentication />}>
                <Route path={ROUTES.Authentication.Login} element={<Login />} />
            </Route>
            <Route path={ROUTES.Default} element={<App />}>
                <Route
                    index
                    path={ROUTES.Default}
                    element={<BeerMenu menuType={MenuTypesEnum.ByFormat} />}
                />
                <Route
                    index
                    path={ROUTES.NoRGB}
                    element={<BeerMenu menuType={MenuTypesEnum.NoRGB} />}
                />
                <Route
                    index
                    path={ROUTES.RGB}
                    element={<BeerMenu menuType={MenuTypesEnum.RGB} />}
                />
            </Route>
        </Routes>
    </BrowserRouter>
);

export default Routing;
