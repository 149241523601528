import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { MenuTypesEnum } from '@/enums/menu-type.enum';
import useGetMenuData from '@/hooks/useGetMenuData';
import useLanguage from '@/hooks/useLanguage';
import { ByFormatDataType } from '@/types/response-data';

import FormatFilters from '../FormatFilters';

import OneColLayout from './partials/OneColLayout';
import TwoColLayout from './partials/TwoColLayout';

import { BeerMenuContainer, BeerMenuH1, BeerMenuHeadingContainer, BeerMenuLayout } from './styles';

type Props = {
    menuType: string;
};

const BeerMenu: React.FC<Props> = ({ menuType }) => {
    const [isByFormatRoute, setIsByFormatRoute] = useState<boolean>(false);
    const { data } = useGetMenuData({ menuType });
    const [byFormatData, setByFormatData] = useState<ByFormatDataType>();
    const { t } = useTranslation('translation');
    const { setLanguage } = useLanguage();

    useEffect(() => {
        setIsByFormatRoute(menuType === MenuTypesEnum.ByFormat);
    }, [menuType]);

    return (
        <>
            {isByFormatRoute && (
                <FormatFilters menuType={menuType} setByFormatData={setByFormatData} />
            )}

            <BeerMenuLayout>
                <BeerMenuContainer $height={isByFormatRoute ? 85 : 100}>
                    <BeerMenuHeadingContainer onClick={setLanguage}>
                        <BeerMenuH1>{t('MENU')}</BeerMenuH1>
                    </BeerMenuHeadingContainer>

                    {isByFormatRoute ? (
                        <OneColLayout data={byFormatData} />
                    ) : (
                        <TwoColLayout data={data} />
                    )}
                </BeerMenuContainer>
            </BeerMenuLayout>
        </>
    );
};

export default BeerMenu;
