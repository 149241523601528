import React from 'react';

import { ROUTES } from '@constants';
import { Navigate, useLocation } from 'react-router-dom';

import { isLoggedIn } from '@/utils/authUtils';

type Props = {
    publicRoutes: string[];
    children: JSX.Element | JSX.Element[];
};

const AuthGuard: React.FC<Props> = ({ publicRoutes, children }) => {
    const { pathname } = useLocation();

    const routeIsPublic = publicRoutes.includes(pathname);

    let result: JSX.Element | JSX.Element[];

    if (isLoggedIn() || routeIsPublic) {
        result = children;
    } else {
        result = <Navigate to={ROUTES.FullPaths.Login} />;
    }

    return <>{result}</>; // eslint-disable-line
};

export default AuthGuard;
