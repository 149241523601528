import { useEffect } from 'react';

import { Content } from 'antd/es/layout/layout';
import { Outlet, useLocation } from 'react-router-dom';

import { initGA, logPageView } from './analytics';
import FooterContent from './components/FooterContent';
import NavigationLinks from './components/NavLinks';
import './config/i18n';
import PUBLIC_ROUTES from './constants/routes/public-routes';
import { AuthGuard } from './guards';
import './index.css';
import { StyledFooter, StyledHeader, StyledLayout } from './styles';

function App() {
    const location = useLocation();

    // Google analytics tracking
    useEffect(() => {
        initGA();
    }, []);

    useEffect(() => {
        logPageView();
    }, [location]);

    return (
        <AuthGuard publicRoutes={PUBLIC_ROUTES}>
            <StyledLayout>
                <StyledHeader>
                    <NavigationLinks />
                </StyledHeader>
                <Content>
                    <Outlet></Outlet>
                </Content>
                <StyledFooter>
                    <FooterContent />
                </StyledFooter>
            </StyledLayout>
        </AuthGuard>
    );
}

export default App;
