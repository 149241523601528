import { useState } from 'react';

import axios from 'axios';

import envconfig from '@/config/envconfig';

type DispatchRequest = {
    email: string;
    password: string;
};

const useLogin = () => {
    const [isLoading, setIsLoading] = useState(false);

    const dispatchLogin = async (request: DispatchRequest) => {
        setIsLoading(true);

        return await axios.post(envconfig.authenticationUrl, JSON.stringify(request), {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    };

    return {
        loading: isLoading,
        dispatchLogin
    };
};

export default useLogin;
