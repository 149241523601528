import React, { useEffect, useState } from 'react';

import { Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '@/constants';
import { LOCAL_STORAGE_KEYS } from '@/constants/keys';
import useLogin from '@/hooks/useLogin';
import { setCacheVal } from '@/services/utils/cacheUtils';
import { isLoggedIn } from '@/utils/authUtils';

import {
    AuthButton,
    AuthButtonsContainer,
    AuthForm,
    AuthFormContainer,
    AuthHeader,
    AuthHeaderContainer
} from './styles';

const Login: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const { dispatchLogin } = useLogin();
    const navigate = useNavigate();
    const { t } = useTranslation('translation');

    type FormValues = {
        email: string;
        password: string;
    };

    const onFinish = (values: FormValues) => {
        setLoading(true);

        dispatchLogin({
            email: values.email,
            password: values.password
        })
            .then(async (res) => {
                if (res.data) {
                    setLoading(false);
                    setCacheVal(LOCAL_STORAGE_KEYS.Token, res.data);
                    navigate(ROUTES.Default);
                    message.success(t('LOGIN_SUCCESS'));
                }
            })
            .catch(() => {
                setLoading(false);
                message.error(t('LOGIN_FAIL'));
            });
    };

    useEffect(() => {
        if (isLoggedIn()) {
            setLoading(false);
            navigate(ROUTES.Default);
        }
    }, []); // eslint-disable-line

    return (
        <AuthFormContainer>
            <AuthHeaderContainer>
                <AuthHeader>{t('LOGIN_HEADER')}</AuthHeader>
            </AuthHeaderContainer>

            <AuthForm
                name="basic"
                labelCol={{ span: 20 }}
                wrapperCol={{ span: 24 }}
                onFinish={onFinish}
                autoComplete="off">
                <Form.Item
                    name="email"
                    rules={[
                        {
                            required: true,
                            type: 'email',
                            message: t('INVALID_EMAIL')
                        }
                    ]}>
                    <Input placeholder={t('EMAIL')} size="large" autoComplete="username" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: t('INVALID_PASSWORD')
                        }
                    ]}>
                    <Input.Password
                        placeholder={t('PASSWORD')}
                        size="large"
                        autoComplete="current-password"
                    />
                </Form.Item>

                <AuthButtonsContainer>
                    <AuthButton htmlType="submit" loading={loading}>
                        {t('LOGIN')}
                    </AuthButton>
                </AuthButtonsContainer>
            </AuthForm>
        </AuthFormContainer>
    );
};

export default Login;
