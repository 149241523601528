import { useTranslation } from 'react-i18next';

import { logoMap } from '@/constants/logo-map';
import { PocSegmentTypeEnum } from '@/enums/poc-type.enum';
import { BeerSubcategoryType, PocSegmentType, RGBDataType } from '@/types/response-data';

import {
    BeerLogo,
    BeerMenuWrapper,
    BeerNameContainer,
    BeerSubcategoryContainer
} from '../../styles';

import {
    BeerMenuTwoCol,
    PriorityCategory,
    TwoColContainer,
    TwoColLogoContainer,
    TwoColName,
    TwoColSubcategoryLabel
} from './styles';

type Props = {
    data: RGBDataType | undefined;
};

const TwoColLayout: React.FC<Props> = ({ data }) => {
    const { t } = useTranslation('translation');

    return (
        <BeerMenuTwoCol>
            {data?.map((item: PocSegmentType, idx: number) => (
                <BeerMenuWrapper key={idx}>
                    <PriorityCategory>
                        {item.pocSegmentType === PocSegmentTypeEnum.Core ? t('CORE') : t('PREMIUM')}
                        <hr />
                    </PriorityCategory>

                    {item.beerSubcategories?.map(
                        (beerSubcategory: BeerSubcategoryType, idx: number) => (
                            <BeerSubcategoryContainer key={idx}>
                                <TwoColSubcategoryLabel>
                                    {beerSubcategory.beerSubcategoryEnum === 1
                                        ? t('DRAUGHT')
                                        : beerSubcategory.beerSubcategoryEnum === 2
                                        ? t('GLASS')
                                        : t('NON_ALCOHOLIC')}
                                </TwoColSubcategoryLabel>
                                {beerSubcategory.beers.map((beer, idx) => (
                                    <TwoColContainer key={idx}>
                                        <TwoColLogoContainer>
                                            <BeerLogo src={logoMap[beer.id]} alt={beer.beerName} />
                                        </TwoColLogoContainer>
                                        <BeerNameContainer>
                                            <TwoColName>{beer.beerName}</TwoColName>
                                        </BeerNameContainer>
                                    </TwoColContainer>
                                ))}
                            </BeerSubcategoryContainer>
                        )
                    )}
                </BeerMenuWrapper>
            ))}
        </BeerMenuTwoCol>
    );
};

export default TwoColLayout;
