import becks from '@/assets/images/beer-logos/becks.webp';
import burgasko from '@/assets/images/beer-logos/burgasko.webp';
import corona from '@/assets/images/beer-logos/corona.webp';
import franziskaner from '@/assets/images/beer-logos/franziskaner.webp';
import fresh from '@/assets/images/beer-logos/fresh.webp';
import guinnessKeg from '@/assets/images/beer-logos/guinnessKeg.webp';
import guinnessOW from '@/assets/images/beer-logos/guinnessOW.webp';
import hoegaarden from '@/assets/images/beer-logos/hoegaarden.webp';
import kamenitza from '@/assets/images/beer-logos/kamenitza.webp';
import kilkenny from '@/assets/images/beer-logos/kilkenny.webp';
import leffeBlond from '@/assets/images/beer-logos/leffe-blond.webp';
import leffeBrown from '@/assets/images/beer-logos/leffe-brown.webp';
import madriExcepcional from '@/assets/images/beer-logos/madriExcepcional.webp';
import staropramen from '@/assets/images/beer-logos/staropramen.webp';
import staropramen0 from '@/assets/images/beer-logos/staropramen0.webp';
import staropramenDark from '@/assets/images/beer-logos/staropramenDark.webp';
import stella from '@/assets/images/beer-logos/stella.webp';
import stella0 from '@/assets/images/beer-logos/stella0.webp';

export const logoMap: Record<number, string> = {
    1: kamenitza,
    2: burgasko,
    3: stella,
    4: staropramen,
    5: leffeBlond,
    6: hoegaarden,
    7: franziskaner,
    8: guinnessKeg,
    9: kilkenny,
    10: stella,
    11: becks,
    12: corona,
    13: leffeBlond,
    14: leffeBrown,
    15: hoegaarden,
    16: guinnessOW,
    17: franziskaner,
    18: kamenitza, // kamenitza 0
    19: stella0,
    20: staropramen0,
    21: fresh, // apple-pear
    22: fresh, // rasp
    23: kamenitza,
    24: burgasko,
    25: staropramen,
    26: madriExcepcional,
    27: staropramenDark,
    28: stella,
    29: becks,
    30: kamenitza,
    31: madriExcepcional,
    32: madriExcepcional
};
